import './App.css';
import {useState, useEffect} from 'react';


let isFirst = true;

function App() {

  const [appData, setAppData] = useState({});

  //TODO: Why "useEffect(()=>{}, [])" called twice? It should be called only once.
  //      Is it because of DEBUG mode, and in PRODUCTION mode will be only one call? Should be verified.
  //      For now use workaround with global variable 'isFirst'.
  useEffect(
    () => {
      if(isFirst) {
        isFirst = false;
        fetch(`${process.env.PUBLIC_URL}/app_data.json`, {method: "GET"})
        .then((res) => res.json())
        .then((data) => setAppData(data || {}))
        .catch(ex => console.error(`EXCEPTION: ${ex.toString()}`));
      }
    },
    []
  );

  return (
    <div className="center">
      <img className="back" src={process.env.PUBLIC_URL + './under_block_256.png'} alt={''} />
      <div className="inner">
        <div><h1>{appData.domain_name}</h1></div>
        <div><h2>{appData.under_construction}</h2></div>
      </div>
    </div>
  );
}

export default App;
